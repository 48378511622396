<template>
  <div class="content">
    <PageHeader :title="$t('sms.templates.header')" /> <!-- Templates SMS -->
    <section class="page-content container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-md-6 col-lg-4 col-sm-12">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <button
                        v-modal="{ target: 'create-template' }"
                        class="btn btn-success"
                      >
                        <i class="icon dripicons-plus"></i>{{$t('sms.templates.lbl-create')}} <!-- Criar novo template -->
                      </button>
                      <button
                        class="btn btn-danger btn-remove border-radius-right"
                        :class="{
                          'qt-loader qt-loader-mini qt-loader-right': isSending,
                        }"
                        @click="deleteAll"
                        v-bind:disabled="
                          selectedTemplates.length == 0 || isSending
                        "
                      >
                        <i class="icon dripicons-trash color-white"></i>{{$t('generic-str.delete')}}<!-- Excluir -->
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body block-el p-0">
              <div class="responsive-table">
                <div
                  v-if="!templates.length && fetched"
                  class="text-center table-placeholder"
                >
                  <i
                    class="fas fa-envelope font-size-80"
                    v-tooltip.top="
                      $t('sms.templates.lbl-tooltip')
                    "
                  ></i>
                  <h5 class="card-title m-t-20">{{$t('sms.templates.none')}}</h5><!-- Nenhum template cadastrado -->
                  <p>{{$t('sms.template.register')}}</p><!-- Cadastre seu primeiro template. -->
                </div>
                <table v-if="fetched" class="table">
                  <thead v-if="templates.length" class="bg-light">
                    <tr>
                      <th class="w-60" scope="col">
                        <div class="custom-control custom-checkbox">
                          <input
                            v-model="selectAllTemplates"
                            type="checkbox"
                            class="custom-control-input"
                            id="customCheckTAll"
                          />
                          <label
                            class="custom-control-label v-align-top"
                            for="customCheckTAll"
                          ></label>
                        </div>
                      </th>
                      <th scope="col">ID</th>
                      <th scope="col">{{$tc('generic-str.name', 1)}}</th><!-- Nome -->
                      <th scope="col">{{$tc('generic-str.message', 1)}}</th><!-- Mensagem -->
                      <th scope="col">{{$t('sms.templates.lbl-created')}}</th><!-- Criado em -->
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(template, i) in templates" :key="template.id">
                      <td data-label="">
                        <div class="custom-control custom-checkbox">
                          <input
                            v-model="selectedTemplates"
                            type="checkbox"
                            class="custom-control-input"
                            :id="'template-check' + i"
                            :value="template"
                          />
                          <label
                            class="custom-control-label v-align-top"
                            :for="'template-check' + i"
                          ></label>
                        </div>
                      </td>
                      <td data-label="ID">
                        <div class="max-200" v-tooltip.top="`${template.id}`">
                          {{ template.id }}
                        </div>
                      </td>
                      <td data-label="Nome">{{ template.name }}</td>
                      <td data-label="Mensagem">{{ template.body }}</td>
                      <td data-label="Data">{{ template.created_at }}</td>
                      <td data-label="">
                        <div class="dropdown">
                          <a
                            role="button"
                            class="dropdown-action"
                            :to="'#menu-dropdown-' + i"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i class="icon dripicons-gear text-success"></i>
                          </a>
                          <div
                            class="dropdown-menu dropdown-menu-right"
                            :id="'menu-dropdown-' + i"
                            x-placement="bottom-end"
                            style=""
                          >
                            <a
                              class="dropdown-item"
                              v-modal="{
                                target: 'template-modal',
                                data: template,
                              }"
                              >{{$t('generic-str.edit')}}</a> <!-- Editar -->
                            <a
                              class="dropdown-item"
                              @click="
                                deleteTemplate(
                                  template.id,
                                  template.name,
                                  template.body,
                                  template.created_at,
                                )
                              "
                              >{{$t('generic-str.remove')}}</a
                            >
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div
                  v-else
                  class="qt-block-ui relative"
                  style="padding: 120px"
                />
                <br />
                <pagination :lastPage="pages" @change="fetch" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <create-template-modal id="create-template" @submit="fetch(1)" />
    <edit-template-modal id="template-modal" @submit="fetch(1)" />
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/PageHeader.vue';
import CreateTemplateModal from '@/components/sms/templates/CreateTemplateModal.vue';
import EditTemplateModal from '@/components/sms/templates/EditTemplateModal.vue';
import SmsService from '@/services/sms.service';
import Swal from 'sweetalert2';
import Pagination from '@/components/Pagination.vue';

export default {
  name: 'SmsTemplates',
  components: {
    PageHeader,
    CreateTemplateModal,
    EditTemplateModal,
    Pagination,
  },
  data() {
    return {
      isSending: false,
      fetched: false,
      templates: [],
      form: {
        page: 1,
      },
      pages: 1,
      selectedTemplates: [],
    };
  },
  computed: {
    selectAllTemplates: {
      get() {
        if (this.templates) {
          return this.selectedTemplates.length === this.templates.length;
        }
        return false;
      },
      set(value) {
        const selected = [];

        if (value) {
          this.templates.forEach((client) => {
            selected.push(client);
          });
        }

        this.selectedTemplates = selected;
      },
    },
  },
  created() {
    this.fetch(1);
  },
  methods: {
    fetch(page) {
      this.form.page = page;
      this.fetched = false;
      SmsService.getTemplates(this.form).then(
        (response) => {
          this.fetched = true;
          this.templates = response.data;
          this.pages = response.last_page;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    deleteAll() {
      Swal.fire({
        title: this.$t('template-component.remove'),
        text: this.$t('template-component.remove-text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          const toDelete = [];
          this.selectedTemplates.forEach((item) => {
            toDelete.push(item.id);
          });
          this.isSending = true;
          SmsService.deleteTemplates({ ids: toDelete }).then(
            () => {
              this.$toast.show({
                title: this.$t('generic-str.success'),
                content: this.$t('template-component.deleted'),
                type: 'success',
              });
              this.fetch(1);
              this.isSending = false;
              this.selectedTemplates = [];
            },
            (error) => {
              console.log(error);
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
                content: error.response.data.message,
                type: 'danger',
              });
              this.isSending = false;
            },
          );
        }
      });
    },
    deleteTemplate(id, name, body, createdAt) {
      const blockquoteStyle =
        'padding: 1rem;font-size: 0.85rem;background-color: #f2f2f2;font-style: italic;line-height: 25px;text-align: start;width: 90%;margin: 1rem auto 0;border-radius: 6px;';
      Swal.fire({
        title: 'Remover template',
        html: `Tem certeza que gostaria de remover o template:
        <blockquote style="${blockquoteStyle}">
          <strong>ID:</strong> ${id}<br>
          <strong>Nome do template:</strong> ${name}<br>
          <strong>Mensagem:</strong> ${body}<br>
          <strong>Criado em:</strong> ${createdAt}<br>
        </blockquote>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.isConfirmed) {
          this.fetchedItens = false;
          this.isSending = true;
          Promise.all([SmsService.deleteTemplate(id)])
            .then(
              (responses) => {
                console.log(responses);
                this.$toast.show({
                  title: 'Sucesso',
                  content: 'Template removido',
                  type: 'success',
                });
                this.fetch(1);
                this.isSending = false;
                this.fetchTemplates(1);
              },
              (error) => {
                this.content = error;
              },
            )
            .finally(() => {
              this.fetchedItens = true;
            });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.btn i {
  line-height: 1;
  color: inherit;
}
</style>
